import Console from './components/Console';

import './App.css';

function App() {
  return (
    <div className="App">
      <Console/>
    </div>
  );
}

export default App;
