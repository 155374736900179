function echo(input) {

    function parse(input) {
        return input
    }

    return parse(input)
}

echo.help = "echo [Any string you want to print]" 

export default echo